import Title from "./components/Title";
import Back from "./components/Back";
import ContentText from "../../components/ContentText";
import TextHeader from "../../components/TextHeader";
import "./detail.css";
import {useEffect, useState} from "react";
import MoreButton from "./components/MoreButton";
import ContentBlock from "../../components/ContentBlock";
import {useLoaderData} from "react-router-dom";
import {addAds} from "../../utils";
import LazyAds from "../../components/LazyAds";
import ContentCard from "../../components/ContentCard";
import OversizedContentCard from "../../components/OversizedContentCard";
import ContentList from "../../components/ContentList";
import ListLoading from "../../components/ListLoading";
import AdsArea from "../../components/AdsArea";
import ContentHeader from "../../components/ContentHeader";

const adsPosition = [3, 9];
export default function Detail() {
    const [showMore, setShowMore] = useState(false);
    const article = useLoaderData();
    const [page, setPage] = useState(1);
    const [next, setNext] = useState(1);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadData = () => {
        setIsLoading(true);
        const url = `https://service.gamescdn.top/feed/${article.id}_${page}.json`;
        if (page === 1) {
            setData([]);
        }
        fetch(url).then(response => {
            return response.json();
        }).then(result => {
            const {next, data: pageData} = result;
            addAds(pageData, adsPosition);
            if (page > 1) {
                setData([...data, ...pageData]);
            } else {
                setData([...pageData])
            }
            setIsLoading(false);
            setNext(next);
        }).catch(e => {
            alert('Load error, please refresh this page');
            setIsLoading(false);
        });
    }

    useEffect(() => {
        loadData();
    }, [page])

    const items = [];
    let adsIndex = 0;
    for (let i = 0; i < data.length; i++) {
        const {title, icon, published_time: publishedTime, ads, type = 1, id} = data[i]
        if (ads) {
            items.push(<LazyAds index={adsIndex} page={"detail"} key={`ads_${adsIndex}`}/>)
            adsIndex++;
        } else {
            if (type === 1) {
                items.push(<ContentCard id={id} title={title} icon={icon} publishedTime={publishedTime} key={`${i}`}/>)
            } else {
                items.push(
                    <OversizedContentCard id={id} title={title} icon={icon} publishedTime={publishedTime} key={`${i}`}/>)
            }
        }
    }

    return (
        <section className={"detail-container"}>
            <ContentHeader>
                <AdsArea page={"detail"} categoryIndex={10} />
                <Back/>
                <Title>{article.title}</Title>
            </ContentHeader>
            <ContentText>
                <TextHeader title={"Instructions"}/>
                <div className={"detail-text"} style={showMore ? null : {maxHeight: 72}} dangerouslySetInnerHTML={{
                    __html: article.content
                }}>
                </div>
                {!showMore && <MoreButton onPress={() => setShowMore(true)}/>}
            </ContentText>
            <ContentBlock>
                <TextHeader title={"Recommend"}/>
                <ContentList onBottomReached={() => {
                    if (data.length > 0 && !isLoading && next === 1) {
                        setPage(page + 1);
                    }
                }}>
                    {items}
                    {data.length > 0 && next === 1 && <ListLoading/>}
                </ContentList>
            </ContentBlock>
        </section>);
}


export async function loader({params}) {
    const url = `https://service.gamescdn.top/feed/${params.id}.json`
    const response = await fetch(url);
    return response.json();
}