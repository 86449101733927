import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";
import Detail, {loader as artileLoader} from "./routes/detail";
import Home from "./routes/home/home";
import PrivacyPolicy from "./routes/privacy-policy/privacy-policy";
import ContactMe from "./routes/contact-me/contact-me";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "detail/:id",
        element: <Detail/>,
        loader: artileLoader,
    },
    {
        path: "privacy-policy",
        element: <PrivacyPolicy/>,
    },
    {
        path: "contact-me",
        element: <ContactMe/>,
    },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
