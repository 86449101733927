import VisibleLoad from "./VisibleLoad";

function LazyAds({index,page}) {
    return (
        <VisibleLoad onFirstVisible={() => {
            console.log(`start to load ads:${page}-${index}`);
            window._taboola = window._taboola || [];
            window._taboola.push({
                mode: 'thumb-below-mobile',
                container: `taboola-mobile-below-article-thumbnails-${page}-${index}`,
                placement: `Mobile Below Article thumbnails-${page}-${index}`,
                target_type: 'mix'
            });
        }}>
            <div id={`taboola-mobile-below-article-thumbnails-${page}-${index}`}></div>
        </VisibleLoad>
    )
}

export default LazyAds;