import "./AdsArea.css"
import {useEffect} from "react";

function AdsArea({page,categoryIndex}) {
    useEffect(()=>{
         console.log(`create  big div ${page}`)
         let topAd = document.getElementById("top-ads");
         topAd.innerHTML = `<div id='taboola-mobile-below-article-thumbnails-${page}'></div>`;
          window._taboola = window._taboola || [];
          window._taboola.push({
            mode: 'thumb-below-mobile',
            container: `taboola-mobile-below-article-thumbnails-${page}`,
            placement: `Mobile Below Article thumbnails-${page}`,
            target_type: 'mix'
          });
    }, [categoryIndex])
     
    return (
        <div id={'top-ads'} className={'ads-area'}></div>
    )
}

export default AdsArea;